@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEYag.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVj2ZhZI2eCN5jzbjEETS9weq8-19e7CA.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYYag.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVl2ZhZI2eCN5jzbjEETS9weq8-59U.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
html,
body {
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
h1 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
}
h2 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
}
h3 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
}
p,
ul > li {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
}
ul > li {
  margin: 5px 0;
}
a {
  color: #8f0404;
  font-weight: 700;
  transition: all 0.2s ease-in;
  text-decoration: none;
  outline: none;
}
a:hover {
  color: #111111;
  text-decoration: none;
  outline: none;
}
a:visited,
a:link {
  text-decoration: none;
  outline: none;
}
blockquote {
  font-family: Lato, serif;
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}
pre {
  font-family: Lato, serif;
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}
table > thead > tr > th {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700 !important;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.centered {
  text-align: center;
}
.ka-wrapper {
  width: 100%;
  min-height: calc(100vh - 40px);
  padding: 0 0 40px 0;
}
.ka-wrapper.ka-bg-tiles {
  background: #ffffff url("/public/assets/background/tiles_background.png") repeat fixed center center;
}
.ka-wrapper.ka-bg-stardust {
  background: #ffffff url("/public/assets/background/stardust_background.png") repeat fixed center center;
}
.ka-wrapper.ka-bg-wall {
  background: #ffffff url("/public/assets/background/wall_background.png") repeat fixed center center;
}
.ka-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 15px;
  background-color: #ffffff;
  border-left: 1px dashed #c2c2c2;
  border-right: 1px dashed #c2c2c2;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.ka-title-block {
  text-align: center;
  margin-bottom: 25px;
}
@media screen and (max-width: 480px) {
  .ka-title-block {
    margin-top: 25px;
  }
}
.ka-not-found-block {
  padding-top: 5%;
  text-align: center;
}
.ka-not-found-block .ka-cup {
  margin: 15px 20px;
  max-width: 200px;
}
.ka-logo-block {
  padding-top: 5%;
  text-align: center;
}
.ka-logo-block .ka-logo {
  margin: 15px 20px;
  max-width: 150px;
}
.ka-interview-card {
  padding: 15px;
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.ka-interview-card .ka-syntax-block {
  border-radius: 10px;
}
.ka-interview-update {
  padding-top: 15px;
  text-align: center;
}
.ka-interview-update p {
  font-size: 14px;
  letter-spacing: 1px;
  color: #c2c2c2;
}
.ka-interview-update p span {
  font-weight: 700;
}
.ka-interview-update .ka-logo {
  opacity: 0.2;
  max-width: 85px;
}
.ka-pdf-container {
  padding-top: 20px;
}
.ka-pdf-container .ka-pdf-title {
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 700;
}
.ka-pdf-container .ka-pdf-update {
  margin: -15px 0 0 0;
  padding: 0;
  text-align: center;
}
.ka-pdf-container .ka-pdf-update p {
  font-size: 14px;
  letter-spacing: 1px;
  color: #c2c2c2;
}
.ka-pdf-container .ka-pdf-update p span {
  font-weight: 700;
}
.ka-pdf-container .ka-pdf-download {
  margin: 0 0 15px 0;
  text-align: center;
}
.ka-pdf-container .ka-pdf-download a {
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.ka-pdf-container .ka-pdf {
  width: 772px;
  height: 1000px;
  margin: 0 auto;
  border: 2px solid transparent;
  text-align: center;
  transition: all 0.5s ease-in;
}
.ka-pdf-container .ka-pdf.loaded {
  border: 2px solid #c2c2c2;
}
.ka-pdf-container .ka-pdf-pagination {
  margin: 10px auto;
  text-align: center;
  opacity: 0;
  transition: all 0.5s linear;
}
.ka-pdf-container .ka-pdf-pagination.loaded {
  opacity: 1;
}
.ka-pdf-container .ka-pdf-pagination .ka-pdf-pagination-pages {
  margin: 0;
  padding: 0;
  font-size: 14px;
  letter-spacing: 1px;
  color: #111111;
}
.ka-pdf-container .ka-pdf-pagination .ka-pagination-navigation a {
  padding: 5px;
  font-size: 34px;
}
.ka-pdf-container .ka-pdf-pagination .ka-pagination-navigation a.active {
  cursor: pointer;
  color: #8f0404;
}
.ka-pdf-container .ka-pdf-pagination .ka-pagination-navigation a.active.hover {
  color: #111111;
}
.ka-pdf-container .ka-pdf-pagination .ka-pagination-navigation a.inactive {
  cursor: default;
  color: #c2c2c2;
}
.ka-pdf-container .ka-pdf-pagination .ka-pagination-navigation a.inactive.hover {
  color: #c2c2c2;
}
.ka-application-container {
  padding-top: 20px;
}
.ka-application-container .ka-title {
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 700;
}
.ka-application-container .ka-hidden-content {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: -10000;
  z-index: -1000;
  height: 0;
  width: 0;
}
.ka-application-container .ka-hidden-content img {
  opacity: 0;
  height: 0;
  width: 0;
}
.ka-application-container .ka-legend-container {
  max-width: 275px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}
.ka-application-container .ka-legend-container p {
  font-weight: bold;
}
.ka-application-container .ka-legend-container .ka-legend-note {
  font-family: "Courier New", Courier, monospace;
  margin: 5px 0;
  text-align: left;
  color: #111111;
}
.ka-application-container .ka-legend-container .ka-legend-note span {
  font-weight: bold;
  color: #8f0404;
}
